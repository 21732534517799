import { toRaw } from 'vue'
import { usePage } from '@inertiajs/vue3'
import ziggyRoute from '../../vendor/tightenco/ziggy/dist/index.m'

export function route(route, params, absolute, config) {
    if (!config) {
        const ziggy = toRaw(usePage().props.ziggy)

        config = { ...ziggy, location: new URL(ziggy.location) }
    }

    return ziggyRoute(route, params, absolute, config)
}
